@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&family=Raleway&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrolling */
.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: #191c40;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.scroll-to-top:hover {
  background-color: #363b72;
}

/* Header and Footer */
.page-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.team-content {
  padding-bottom: 100px;
}

/* Language Select */
.lang-select-container {
  @apply flex flex-row items-center right-1 mt-3 mb-0 mr-5 text-white;
}

.lang-select-btn {
  @apply p-2 flex flex-row items-center text-sm font-medium text-slate-200 hover:bg-slate-700 focus:outline-none;
}

/* Card */

.card {
  border: 1px solid #ccc;
  cursor: pointer;
  transition: max-height 0.3s;
}

.text-container {
  max-height: 150px;
  overflow: hidden;
  transition: max-height 0.3s;
}

.additional-part {
  display: none;
}

.member-page-photo {
  min-width: 300px;
  max-width: 400px;
  height: auto;
}

.wechat-photo {
  height: 150px;
  width: 150px;
}
